import React, { useState, useEffect,nav } from 'react';
import { navigate } from "gatsby"
import { HOME } from '../../constants/routes'
// import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { compose } from 'recompose';
import Layout from '../../components/layout';
import {
    withAuthorization,
    withEmailVerification,
} from '../../components/Session';
import {AuthUserContext} from '../../components/Session';
import Devices from "../../components/Devices"
import { connect } from "react-redux";
import { getPlace, getActiveDevice } from "../../redux/selectors";
import { setPlace, setActiveDevice } from "../../redux/actions";
import { Segment,Dimmer,Loader, Container, Header,Icon,Label } from 'semantic-ui-react'




const PlacePageBase = (props) => {
    const [placeId, setPlaceId] = useState(props.params.id)
    const [firebase, setFirebase] = useState(null)
    const [placeObj, setPlaceObj] = useState(null)
    const [detailPlaceObj, setDetailPlaceObj] = useState(null)
    const [hasPermission, setHasPermission] = useState(true)
    const [activeDevice, setActiveDeviceOnState] = useState(props.activeDevice && props.activeDevice.device ? props.activeDevice.device : null)

    const fetchDeviceDetails = async (place) => {
        if(firebase && place){
            const newPlace = {...place};
            const devices = [];
            // new Array(place.devices.length)

            // for(let i =0 ;i < place.devices.length; i+=10){
            //   const snapshot = await firebase.devicesByPlaceId(place.devices.slice(i,i+10)).get();
            //   snapshot.forEach(doc => {
            //     let deviceObject = doc.data();
            //     // console.log(place.devices)   
            //     if (deviceObject ) {
            //         let idx  = place.devices.indexOf(doc.id)
            //         devices[idx] =  { id: doc.id, ...deviceObject }
            //     }
            //   });
            // }
            const snapshot = await firebase.devicesByPlaceId(placeId).get();
            snapshot.forEach(doc => {
                // console.log(doc);
                let deviceObject = doc.data();
                if (deviceObject ) {
                    devices.push({ id: doc.id, ...deviceObject });
                }
            });

            newPlace.devices = devices;
            if(!activeDevice || devices.map(d=>d.id).indexOf(activeDevice.id) < 0)
                setActiveDeviceOnState(devices[0])
            // console.log(props.params.id,newPlace)
            setDetailPlaceObj(newPlace);
            return newPlace;
        }
        else return null;
    }

    useEffect(() => {
        // console.log(props)
        if (firebase == null)
            setFirebase(props.firebase)
        // console.log(`placeId: ${placeId}`)
        if (placeId) {
            if (firebase) {
                firebase.place(placeId)
                    .get()
                    .then(doc => {
                        let place = doc.data()
                        if (place === undefined){
                            navigate(HOME)
                        }else{
                            // console.log(props.authUser.email)
                            // console.log(place.user_emails)
                            if (place.public || props.authUser.isVip || (place.user_emails && place.user_emails.includes( props.authUser.email)) ){
                                setPlaceObj(place)
                                props.setPlace(place)
                            }
                            else{
                                setHasPermission(false);
                            }
                        }
                        // props.setPlace(place)
                        // props.setActiveDevice(place.devices[0])
                    })
            }
        }else{
            if (firebase) {
                navigate(HOME)
            }
        }
        if(placeObj && !detailPlaceObj){
            // console.log(placeObj)
            fetchDeviceDetails(placeObj);
        }

    }, [props.firebase, firebase]);

    // useEffect(() => {
       
    //     setPlaceObj(props.place)
    // }, [props.place])
    useEffect(() => {
        if(props.activeDevice && props.activeDevice.device ){
            // console.log(props.activeDevice)
            setActiveDeviceOnState(props.activeDevice.device )
        }
    }, [props.activeDevice])

    useEffect(() => {
       
        fetchDeviceDetails(placeObj)
    }, [placeObj])
    // if(detailPlaceObj)
    // console.log(props.activeDevice.device,activeDevice)
    return (
        <Container style={{width:`1185px`}}>
            {
                hasPermission && detailPlaceObj &&
                <>
                    <Segment>
                        <Header>{detailPlaceObj.name}
                        </Header>
                    </Segment>
                    <Devices 
                        devices={detailPlaceObj.devices} 
                        activeDevice={activeDevice} 
                        setActiveDevice={props.setActiveDevice}
                        placeAnalyticsConfig={placeObj?.analytics_config}
                        />
                </>

            }
            {
                hasPermission && !detailPlaceObj &&
                <Dimmer active inverted>
                    <Loader size="big" inverted>Loading</Loader>
                </Dimmer>
            }
            {
                !hasPermission && 
                <Segment placeholder>
                    <Header icon> <Icon name='dont' color="red"/>คุณไม่สามารถดูหน้านี้ได้ <br/>You don't have permission to access   </Header>
                </Segment>
            }
        </Container>
    )
};

const condition = authUser => !!authUser;

const mapStateToProps = state => {
    const place = getPlace(state)
    const activeDevice = getActiveDevice(state)
    return { ...place, activeDevice }
}

const PlacePage = compose(
    withEmailVerification,
    withAuthorization(condition, `?src=${typeof window !== 'undefined' && window.location.href}`)
    ,connect(mapStateToProps, { setPlace, setActiveDevice })
)(PlacePageBase);

export default ({params}) => (
    <Layout>
        <AuthUserContext.Consumer>
            {authUser => <PlacePage params={params} authUser={authUser}  />}
        </AuthUserContext.Consumer>
    </Layout>
);