    import { SET_PLACE, SET_ACTIVE_DEVICE } from "./actionTypes"

    export const setPlace = place => ({
        type: SET_PLACE,
        payload : {
            place
        }
    })

    export const setActiveDevice = device => ({
        type: SET_ACTIVE_DEVICE,
        payload : {
            device
        }
    }) 